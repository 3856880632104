<template>
  <div>
    <div v-if="list.length === 0" class="mt-8">
      <NoRecordFound />
    </div>
    <div v-else class="mt-8">
      <TableWrapper>
        <THead>
          <TRHead>
            <TH
              v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
              v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
              :key="head.id"
            >
              {{ head.dataTableName }}
            </TH>
            <TH v-permission="campusClassLevelPermisions">Section</TH>
            <TH
              v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])"
              v-permission="tableActionsPermissions"
            >
              Actions
            </TH>
          </TRHead>
        </THead>
        <TBody>
          <TRBody v-for="(userItem, index) in usersList" :key="index">
            <TD v-show="showColumnInDataTable(filtersData, 'Student Name', ['Student Name'])">
              <div>
                <SingleUserDisplay :user="userItem.user" label="full_name" :image="true" />
              </div>
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Title')">
              <span
                class="text-primary-purple-600 capitalize cursor-pointer"
                @click="redirectToDetailPage(userItem)"
              >
                {{ userItem.section_attendance && userItem.section_attendance.title }}
              </span>
            </TD>

            <TD v-show="showColumnInDataTable(filtersData, 'Attendance Time', ['Attendance Time'])">
              <span class="capitalize flex justify-center">
                {{
                  $filters.getTimeOnly(
                    userItem.section_attendance.marked_at,
                    TIME_CONSTANTS.time12H,
                  )
                }}
              </span>
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Marked at', ['Marked at'])">
              <span class="capitalize flex justify-center">
                {{ $filters.getTimeOnly(userItem.inserted_at, TIME_CONSTANTS.DATE_TIME_12) }}
              </span>
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Comment')">
              {{ userItem.comment || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </TD>
            <TD v-show="showColumnInDataTable(filtersData, 'Status', ['Status'])">
              <UiStatus :title="userItem.status" :success="userItem.status === PRESENT" />
            </TD>
            <TD
              v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
              v-permission="campusClassLevelPermisions"
            >
              <BadgeDisplay
                :key="`badge${forceRerender}`"
                :options="userItem.section_attendance.section"
                label="title"
              />
            </TD>
            <TD
              v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])"
              v-permission="tableActionsPermissions"
            >
              <TableAction
                :action-list="actionList"
                :current-user="userItem"
                :idx="index"
                @action="editAttendance"
              />
            </TD>
          </TRBody>
        </TBody>
      </TableWrapper>
      <ValidationObserver @submit="updateAttendance">
        <UiModalContainer
          footer-classes="flex justify-end"
          :modal-show="showEditAttendanceModal"
          :modal-width="40"
          @handleClick="toogleAttendanceModal"
        >
          <template v-slot:header>Edit Attendance for {{ activeObj.user.full_name }}</template>
          <template v-slot>
            <div>
              <InputFieldWrapper>
                <UiSingleSelect
                  v-model="activeObj.status"
                  title="STATUS"
                  rules="required"
                  :label="'title'"
                  :options="['present', 'absent']"
                  class="text-text-color font-roboto text-sm font-normal flex-1"
                />
              </InputFieldWrapper>
            </div>
          </template>
          <template v-slot:footer>
            <div class="flex gap-3 rtl:gap-x-4">
              <UIButton @click="toogleAttendanceModal">Cancel</UIButton>
              <UIButton button="primary">
                <span v-if="false">
                  <Loader />
                </span>
                <span v-else>
                  <span>Update</span>
                </span>
              </UIButton>
            </div>
          </template>
        </UiModalContainer>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import TableAction from '@components/TableAction.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@components/UiElements/UiSingleSelect.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import { mapActions } from 'vuex'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import { SCOPE_LEVELS } from '@src/constants/general-constants'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import fileMixin from '@src/mixins/file-mixins'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { mapState } from 'vuex'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    SingleUserDisplay,
    TableAction,
    UiModalContainer,
    UiSingleSelect,
    ValidationObserver,
    NoRecordFound,
    UIButton,
    UiStatus,
    InputFieldWrapper,
    TableWrapper,
    TBody,
    BadgeDisplay,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [fileMixin, scrollMixin],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },

    currentDate: {
      type: [Date, String],
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    filtersData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update'],
  data() {
    return {
      PRESENT: 'present',
      dashboard: 'dashboard',
      TIME_CONSTANTS,
      forceRerender: 0,
      GENERAL_CONSTANTS,
      history: false,
      attendanceTranslation: 'attendanceTranslation',
      usersList: [],
      historyData: [],
      title: 'Teacher',
      skipForFilterColumns: ['Student Name', 'Attendance Time', 'Marked at', 'Status'],
      actionList: [{ name: 'Edit', roles: ['super_admin', 'campus_admin', 'section_teacher'] }],
      activeObj: {},
      showEditAttendanceModal: false,
      campusClassLevelPermisions: {
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
      },
      tableActionsPermissions: {
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
        roles: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
      },
      classLevelPermissions: {
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL],
      },
    }
  },
  computed: {
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        this.forceRerender++
        /* SETTING EXTRA KEY IN OBJECT FOR IMAGES */
        this.usersList = this.list.map((record) => {
          fullName(record.user)
          return record
        })
      },
    },
  },
  methods: {
    showColumnInDataTable,
    fullName,
    editAttendance(action, activeObj) {
      this.activeObj = objectDeepCopy(activeObj)
      this.toogleAttendanceModal()
    },
    redirectToDetailPage(detail) {
      this.$router?.push({
        name: 'student-section-attendance-detail',
        params: {
          id: detail.section_attendance.id,
        },
      })
    },
    toogleAttendanceModal() {
      this.showEditAttendanceModal = !this.showEditAttendanceModal
    },
    async updateAttendance() {
      const [res, err] = await this.updateAttendanceOfSetionStudent(this.activeObj)
      if (res) {
        this.toogleAttendanceModal()
        this.$emit('update')
      }
    },
    ...mapActions('attendance', ['updateAttendanceOfSetionStudent']),
  },
}
</script>
